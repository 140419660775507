<template>
<div>
    <section>
        <AdvanceTable ref="advanceTable" url="lmd/ordererror/list" :height="window.height - 280" :columns="columns" :pageOptions="[50, 100, 200]" :defaultPageSize="50" @edit="openModalForEdit">
        <template #title>
            {{ $t("API Request Error Log") }}
        </template>
        </AdvanceTable>
    </section>
    <b-modal
      id="api-log"
      centered
      :title="$t('API Log')"
      size="lg"
      v-model="isShow"
      hide-footer
    >
     <b-card no-body>
        <b-card-header>
            <slot name="header">
                <span class="lead">API Log Detail</span>
            </slot>
        </b-card-header>
        <b-card-body style="font-weight:bold"><div>{{requestHeader}}</div></b-card-body>
     </b-card>
     <b-card no-body>
        <b-card-header>
            <slot name="header">
                <span class="lead">Request</span>
            </slot>
        </b-card-header>
        <b-card-body> 
            <json-viewer :expand-depth=2 copyable  sort v-if="showtext" style="margin-top:-15px"
                           :value="showtext ? showtext : ''"></json-viewer>
        </b-card-body>
     </b-card>
     <b-card no-body>
        <b-card-header>
            <slot name="header">
                <span class="lead">Response</span>
            </slot>
        </b-card-header>
        <b-card-body style="font-weight:bold">
            <!-- <json-viewer :expand-depth=2 copyable  sort v-if="showtext2"
                           :value="showtext2 ? showtext2 : ''"></json-viewer> -->
                           {{showtext2}}
        </b-card-body>
     </b-card>
    </b-modal>
  </div>
</template>

<script>
import AdvanceTable from "@core/components/advanceTable/advanceTable.vue";
import JsonViewer from 'vue-json-viewer'
import { readFile } from 'vxe-table';
export default {
  components: {
    AdvanceTable,
    JsonViewer
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      showtext:{},
      showtext2:{},
      columns: [
        { key: "createdAt", modelName: "createdAt", label: "API Request DateTime", width: "250", filtertype: "date", sortable: true },
        { key: "referenceNumber", modelName: "referenceNumber", label: "Cleint Reference Number", width: "300", filtertype: "input", sortable: true },
        { key: "responseBody", modelName: "responseBody", label: "Error Response", filtertype: "input", sortable: true },
      ],
      isShow:false,
      requestHeader:''
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    openModalForEdit(item) {
        this.isShow = true
        this.$http.post('/lmd/ordererror/find', {id:item.id}).then(res=>{
            console.info(res.data,'res')
            this.requestHeader = res.data.data.requestHeader
            this.showtext= JSON.parse(res.data.data.requestBody)
            this.showtext2= res.data.data.responseBody
        })
    },
  }
};
</script>

<style scoped></style>
